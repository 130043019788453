import { AppProps } from "next/app";
import { useEffect, useState } from "react";
import { config } from "@fortawesome/fontawesome-svg-core";

// CSS from libraries
import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "leaflet/dist/leaflet.css";

// CSS overrides
import "@/css/style.css";
import "@/css/bryntum.css";
import "@/css/rsuite.css";
import "@/css/marker.css";

import Head from "next/head";
import { Inter } from "next/font/google";

config.autoAddCss = false;

const inter = Inter({ subsets: ["latin"] });

function App({ Component, pageProps }: AppProps) {
    const [render, setRender] = useState(false);
    useEffect(() => setRender(true), []);

    if (!render) {
        return null;
    }

    return (
        <main className={inter.className}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta name="current-locale" content="en_EN" />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="FacilityApps is a Software as a Service that provides you a complete Mobile Workforce Solution."
                />

                <title>Facility Apps</title>

                <link rel="icon" href="/api/v1/favicon" />
                <link rel="apple-touch-icon" href="/api/v1/favicon" />
            </Head>
            <Component {...pageProps} />
        </main>
    );
}

export default App;
